.App{
  height: 100vh;
  background-color: #2D3142;
  background-image: linear-gradient(#2D3142 70%,#000)
}

.contentWrapper{
  min-height: 100%;
  margin-bottom: -50px;
}

.push{
  height: 50px;
}

.App footer{
  text-align: center;
  color: aliceblue;
  height: 50px;
}

.App footer a{
  color: #F9E000;
}

.App .Name{
  font-family: semplicitapro, sans-serif;
  font-weight: 700;
  font-size: 20px;
  font-style: normal;
  color: #F9E000;
  padding-left: 10%;
}