.Main{
	margin-bottom: 10%;
}
.HeroImage img{
	width: 100%;
	margin-top: 10%;
}
.EventsFeed{
	background-color: rgba(255, 255, 255, 0.2);
}
.EventsFeed .Header{
	color: aliceblue;
}
.EventsFeed .Event{
	margin-top: 10px;
	color: rgb(255, 220, 21);
}
.EventsFeed a{
	margin-top: 10px;
	color: rgb(255, 220, 21);
}

.LearnMore{
	background-color: #79ADDC !important;
	color: black !important;
}

.Disclosure{
	font-size: 7pt;
	color: gray;
	margin-top: 15px;
	text-align: center;
}