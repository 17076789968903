
.InfoSegment{
	background-color: #FCFCFF !important;
}

.SyncButton{
	background-color: #79ADDC !important;
	box-shadow: 0px 3px 5px  !important;
	color: #2D3142 !important;
}

.ProjectImage{
	object-fit: cover;
	width:100%;
}